<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" md="4" offset-md="4" style="padding-bottom: 0px;">
        <v-img
          :src="require('../assets/newsspeak-cogs.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4 pa-0" cols="12" md="4" offset-md="4">
        <h1 class="mid-weight" style="padding-bottom: 2vmin;">
          Coming Soon...
        </h1>
        <p style="padding: 0vmin 5vmin 2vmin 5vmin; text-align: left;">
          No more echo chambers. No more false and misleading coverage. Just news,
          without doublespeak. Newsspeak will collect and deliver quality coverage
          on news that matters from a broad range of sources right to your device.
        </p>

        <h2 style="font-weight: 500; padding: 1vmin 0vmin 1vmax 4vmin; text-align: left;">
          Get Notified When We Launch
        </h2>

        <!-- Begin Mailchimp Signup Form -->
        <div id="mc_embed_signup" style="padding: 0vmin 5vmin 2vmin 5vmin;">
          <v-form ref="form" validate-on-blur
            action="https://app.us20.list-manage.com/subscribe/post?u=c04163c7ae8e9d44c90d971f1&amp;id=91aa0fe0f5" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <div id="mc_embed_signup_scroll">
            
                <v-row>
                  <v-col cols="9">
                    <div class="mc-field-group">
                      <v-text-field placeholder="Email Address" type="email" :rules="emailRules"
                        value="" name="EMAIL" class="required email" id="mce-EMAIL" />
                    </div>

                    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c04163c7ae8e9d44c90d971f1_91aa0fe0f5" tabindex="-1" value=""></div>
                  </v-col>
                  <v-col cols="2">
                    <v-btn @click="validate" value="Subscribe" fab name="subscribe" id="mc-embedded-subscribe">
                      <v-icon>mdi-email-plus-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
          </v-form>
        </div>
        <!--End mc_embed_signup-->

      </v-col>

    </v-row>
  </v-container>
</template>

<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
<script type='text/javascript'>
(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);
</script>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail is not valid'
      ]
    }),
    methods: {
      validate () {
          if (this.$refs.form.validate()) {
              this.$refs.form.$el.submit();
              this.$refs.form.reset();
          } else {
              return;
          }
      },
    }
  }
</script>
