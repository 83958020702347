<template>
        <v-app-bar :height="topbarHeight" app fill-height color="white" elevation="2">
            
            <v-row class="fill-height">
                <v-col md="3" cols="1" class="pa-0 center">
                    <v-app-bar-nav-icon small v-if="isMobile">
                        <v-icon>mdi-menu</v-icon>
                    </v-app-bar-nav-icon>
                </v-col>
                <v-col md="6" cols="10" class="center pa-0">
                        <v-img
                            class="center"
                            alt="Newsspeak Logo"
                            contain
                            src="../assets/logo.png"
                            height="40"
                            width="223"
                        />

                </v-col>
                <v-col md="3" cols="1" align="top" class="pa-0">
                    <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest"
                        v-if="!isMobile && isMobile" target="_blank" text>
                        Login/Sign Up
                    </v-btn>
                </v-col>
            </v-row>
        </v-app-bar>

</template>

<script>
  export default {
    name: 'Topbar',
    data: () => ({
        isMobile: false
    }),
    computed: {
        topbarHeight: function() {
            if (this.isMobile) return 55;
            else return 90;
        }
    },
    beforeDestroy () {
        if (typeof window !== 'undefined') {
        window.removeEventListener('resize', this.onResize, { passive: true })
        }
    },
    mounted () {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize () {
        this.isMobile = window.innerWidth < 800
        }
    }
  }
</script>