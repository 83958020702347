<template>
  <v-app>
    <Topbar />

    <v-main style="margin-top: 1vmax;">
      <UnderConstruction />
    </v-main>
  </v-app>
</template>

<script>
import Topbar from './components/Topbar';
import UnderConstruction from './components/UnderConstruction';

export default {
  name: 'App',

  components: {
    Topbar,
    UnderConstruction,
  },

  data: () => ({
    //
  }),
};
</script>
