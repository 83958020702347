import Vue from 'vue'
import FrontPage from './FrontPage.vue'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(FrontPage)
}).$mount('#app')
